.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--secondary-color);

    .image {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: center center;
        // opacity: 0.7;
    }

    &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.427), rgba(0, 0, 0, 0.4));
    }


    svg.left-arrow:hover,
    svg.right-arrow:hover {
        opacity: 0.7;
    }

    .left-arrow {
        position: absolute;
        top: 50%;
        left: 32px;
        font-size: 3rem;
        color: var(--primary-color);
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }

    .right-arrow {
        position: absolute;
        top: 50%;
        right: 32px;
        font-size: 3rem;
        color: var(--primary-color);
        z-index: 10;
        cursor: pointer;
        user-select: none;
    }

    .slide {
        transition-duration: 3s ease;
    }

    .slide.active {
        transition-duration: 6s;
        transform: scale(1.08);
    }

    .caption {
        color: white;
        position: absolute;
        top: 5vw;
        right: 5vw;
        text-align: right;
        font-size: calc(16px + 2vw);
        z-index: 1;

        p {
            margin: 0;
            line-height: 0;
        }

        small {
            font-size: calc(8px + 0.8vw);
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .count {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: calc(8px + 1vw);
        font-style: italic;
        &-line {
            font-size: calc(36px + 2vw);
            color: var(--primary-color);
        }
    }
}