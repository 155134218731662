@media screen and(max-width: 1024px) {
	.layout{
		display: block;
	}
	
}
@media screen and(max-width: 930px) {
	.layout{
		display: none;
		position: absolute;
    	z-index: 500000;
    	justify-content: center;
    	align-items: center;
    	height: 100%;
    	width: 100%;
    	background-color: black;
    	color: white; 
	}
}

