.project-card {
    position: relative;
    height: 20vw;
    background-color: var(--third-color);
    // font-family: BauerBodoniStd-Roman;

    &-info {
        position: absolute;
        bottom: 1vw;
        padding-left: 1vw;
        color: white;
        text-transform: capitalize;
        line-height: 0.5;

        h4 {
            font-weight: 700;
            font-size: calc(8px + 0.8vw);
        }

        p {
            font-size: calc(8px + 0.5vw);
        }
    }
}