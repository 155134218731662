.header {
  background-color: transparent;
  position: absolute;
  z-index: 10;
  width: 100%;
  // font-family: BauerBodoniStd-Roman;

  .regular-link {
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: calc(0.75rem + 0.3vw);
    // font-size: 0.75rem;
    text-shadow: 0 0 2px var(--primary-color);
    cursor: pointer;
  }

  .regular-link.disabled {
    color: gray;
    text-shadow: none;
    cursor: default;

    &:hover {
      text-shadow: none;
    }
  }

  button.regular-link {
    background-color: transparent;
    border: 3px solid var(--primary-color);
    color: white;
    border-radius: 10px;
    font-weight: 700;
    font-size: calc(0.75rem + 0.3vw);
    text-shadow: 0 0 2px var(--primary-color);
  }

  .regular-link:hover,
  button:hover.regular-link {
    text-shadow: 0 0 3px var(--primary-color), 0 0 5px var(--primary-color);
  }
}

.sqm-logo {
  img {
    width: calc(4rem + 2vw);
  }
}

.slider-description {
  position: absolute;
  bottom: 20px;
  width: 51vw;
  color: white;
  border-bottom: 3px solid var(--primary-color);

  h1 {
    width: 45vw;
    font-size: calc(18px + 3vw);
    // text-shadow: 0 0 5px var(--secondary-color);
  }
  h3 {
    width: 100%;
    font-size: calc(15px + 2vw);
    text-align: center;
  }
}

h3 {
  width: 100%;
  font-size: calc(15px + 2vw);
  text-align: center;
}

.recent {
  &-project {
    position: relative;
    height: 100vh;
    background-color: var(--secondary-color);
    // font-family: BauerBodoniStd-Roman;

    .slider {
      width: 70vw;
      height: 40vw;
      position: static;

      .slide.active {
        transform: none;
        -webkit-animation: scale-up-center 2s
          cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: scale-up-center 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      }

      .left-arrow {
        left: 80vw;
      }

      .right-arrow {
        right: -15vw;
      }

      .caption {
        right: -15vw;

        small {
          font-family: Avenir;
        }

        .count {
          margin-bottom: 2.5vw;

          &-current {
            margin-bottom: 2vw;
          }

          &-total {
            margin-top: 2vw;
          }
        }
      }
    }
  }

  &-title {
    font-size: calc(8px + 0.5vw);
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: 600;
  }
}

.about {
  position: relative;
  height: auto;
  background-color: var(--secondary-color);
  // font-family: BauerBodoniStd-Roman;

  .slider {
    width: 70vw;
    height: 40vw;
  }

  &-title {
    font-size: calc(8px + 0.5vw);
    color: var(--third-color);
    text-transform: uppercase;
    font-weight: 600;
  }

  &-description {
    // font-family: BauerBodoniStd-Roman;

    p {
      color: var(--third-color);
      font-size: calc(14px + 1vw);
      margin: 0;
    }

    h5 {
      color: white;
      font-size: calc(20px + 2vw);

      span {
        color: var(--primary-color);
        font-style: italic;
      }
    }

    video {
      width: 70%;
    }

    .playpause {
      background-image: url(https://h987.b-cdn.net/images/HBA-Video-Play.svg);
      background-repeat: no-repeat;
      width: 10%;
      height: 10%;
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      margin: auto;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }

    .learn-more a {
      position: absolute;
      right: 16%;
      margin: auto;
      color: white;
      font-size: calc(8px + 0.5vw);
      text-transform: uppercase;
      font-weight: 600;
      padding: 0 1rem;
      border-bottom: 1px solid var(--primary-color);
      text-decoration: none;
    }

    .learn-more a:hover {
      text-shadow: 0 0 3px var(--primary-color), 0 0 5px var(--primary-color);
    }
  }
}

.news {
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.427), rgba(0, 0, 0, 0.4)),
    url('../../public/pictures/interior/grand-harvest-surabaya/grand-harvest1.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: var(--secondary-color);
  min-height: 100vh;
  // font-family: BauerBodoniStd-Roman;

  &-title {
    font-size: calc(8px + 0.5vw);
    color: var(--third-color);
    text-transform: uppercase;
    font-weight: 600;
    margin-top: calc(8rem + 5vw);
    margin-bottom: calc(2rem + 1vw);
  }

  small {
    color: var(--primary-color);
    font-size: calc(14px + 1vw);
  }

  h4 {
    color: white;
    font-size: calc(20px + 2vw);
    width: 40%;
  }

  .more a {
    color: white;
    font-size: calc(8px + 0.5vw);
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 2rem;
    padding: 0.7rem 1.5rem;
    border-bottom: 1px solid var(--primary-color);
    text-decoration: none;
  }

  .more a:hover {
    text-shadow: 0 0 3px var(--primary-color), 0 0 5px var(--primary-color);
  }
}

.service {
  background-color: var(--secondary-color);
  // font-family: BauerBodoniStd-Roman;
  height: 100vh;

  &-title {
    font-size: calc(8px + 0.5vw);
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
  }

  .react-3d-carousel .slider-container .slider-left div,
  .react-3d-carousel .slider-container .slider-right div {
    border-color: transparent;
  }

  .fa {
    color: var(--primary-color);
    font-size: 25px;
  }
}

.footer {
  background-color: #3d3634;
  font-family: Avenir;

  h5 {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: calc(8px + 0.5vw);
    border-bottom: 2px solid var(--primary-color);
    display: inline-block;
    padding-bottom: 0.3rem;
  }

  .newsletter-line {
    border-bottom: 1px solid var(--third-color);
    margin: 1rem 0;
  }

  input.newsletter-email {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    display: block;
    width: 100%;
    border: none;
    background: transparent;
    margin-bottom: 10px;
    height: 45px;
    font-size: 15px;
    text-align: left;
    color: white;
    float: left;
    width: 65%;
    opacity: 0.5;
    margin: 0;
  }

  button.newsletter-button {
    border: none;
    background-color: transparent;
    font-size: calc(8px + 0.3vw);
    float: right;
    padding-right: 0;
    font-weight: 600;

    svg {
      color: var(--primary-color);
      font-size: calc(8px + 0.8vw);
    }
  }

  .privacy {
    text-transform: uppercase;
    font-weight: 600;
    font-size: calc(8px + 0.3vw);
    float: right;

    a {
      color: white;
      text-decoration: none;
    }

    a:hover {
      color: var(--primary-color);
    }
  }

  .sosmed {
    color: var(--primary-color);
    font-size: calc(12px + 1vw);

    a {
      text-decoration: none;
      color: var(--primary-color);

      svg {
        color: var(--primary-color);
      }
    }
  }

  .contact {
    color: var(--third-color);
    font-size: calc(8px + 0.3vw);
    text-transform: uppercase;
    font-weight: 600;

    a {
      color: white;
      text-transform: uppercase;
      margin-top: 2rem;
      padding: 0.7rem;
      border-bottom: 1px solid var(--primary-color);
      text-decoration: none;
      margin-left: 1rem;
    }
  }

  .contact a:hover {
    text-shadow: 0 0 3px var(--primary-color), 0 0 5px var(--primary-color);
  }

  .copyright {
    color: var(--third-color);
    font-size: calc(8px + 0.4vw);
  }
}

.location {
  min-height: 20vh;
  background-color: #69615f;
  // font-family: BauerBodoniStd-Roman;

  &-title {
    text-transform: uppercase;
    color: var(--secondary-color);
    font-weight: 600;
    font-size: calc(8px + 0.5vw);
  }

  a,
  a:hover {
    color: white;
    text-decoration: none;
  }
}
.float {
  display: flex;
  padding: 15px;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
