.float-whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 2vw;
    right: 4.3vw;
    background-color: #25d366;
    color: white;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;

    a,
    a:hover {
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 14px;
        color: white;
        text-decoration: none;
    }
}