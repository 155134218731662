.float-modal{
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 2vw;
    right: 0.5vw;
    // background-color: white;
    color: white;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
    // border: 2px solid white;
}

.form-control{
    margin-bottom: 1.5vw;
}