.project {
    &-sidebar {
        position: fixed;
        width: 15vw;
        height: 100vh;
        background: var(--secondary-color);
        font-size: 0.65em;
        // font-family: BauerBodoniStd-Roman;

        .sqm-logo {
            img {
                width: calc(5rem + 5vw);
                margin: 0 10%;
            }
        }

        .nav {
            position: relative;
            margin: 0 15%;
            text-align: right;
            top: 20%;
            transform: translateY(-50%);
            font-weight: bold;
        }

        .nav ul {
            list-style: none;

            li {
                position: relative;
                margin: 3.2em 0;

                a {
                    line-height: 5em;
                    text-transform: uppercase;
                    text-decoration: none;
                    letter-spacing: 0.4em;
                    color: rgba(white, 0.35);
                    display: block;
                    transition: all ease-out 300ms;

                    &.active {
                        color: white;
                    }

                    &:not(.active)::after {
                        opacity: 0.2;
                    }

                    &:not(.active):hover {
                        color: rgba(white, 0.75);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 0.2em;
                        background: black;
                        left: 0;
                        bottom: 0;
                        background-image: linear-gradient(to right, var(--third-color), var(--primary-color));
                    }
                }


            }
        }

    }

    &-content {
        position: relative;
        width: 85vw;
        float: right;
        height: 100vh;

        &-title {
            position: relative;
            font-size: calc(20px + 0.8vw);
            font-weight: 700;
            line-height: 1.5;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 0.2rem;
                left: 0;
                bottom: 0;
                background-image: linear-gradient(to right, var(--third-color), var(--primary-color));
            }
        }
    }
}