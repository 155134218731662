.project {
    &-content-detail {
        position: relative;
        width: 85vw;
        float: right;
        height: 100vh;
        // font-family: BauerBodoniStd-Roman;

        &-info {
            color: var(--secondary-color);
            text-align: justify;
            font-family: Avenir;
            -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

            .info-title {
                position: relative;
                font-size: calc(18px + 1vw);
                font-weight: 700;
                line-height: 1.5;
                // font-family: BauerBodoniStd-Roman;

                &:after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    height: 0.2rem;
                    left: 0;
                    bottom: 0;
                    background-image: linear-gradient(to right, var(--third-color), var(--primary-color));
                }
            }

            .info-location {
                font-size: calc(8px + 0.8vw);
            }

            .info-year {
                font-size: calc(8px + 0.5vw);
            }

            .info-description {
                font-size: calc(8px + 0.5vw);
            }
        }

        button.btn-slider {
            // font-family: BauerBodoniStd-Roman;
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            color: white;
            border-radius: 10px;
            font-weight: 700;
            font-size: calc(0.5rem + 0.5vw);
        }

        .alice-carousel__stage-item img {
            width: 100%;
            height: 100%;

            &:after {
                background: linear-gradient(rgba(0, 0, 0, 0.427), rgba(0, 0, 0, 0.4));
            }

            &:hover {
                cursor: pointer;
                opacity: 0.9;
            }
        }

        .alice-carousel__prev-btn {
            position: absolute;
            top: 30%;
            left: -2.5vw;
            width: auto;

            [data-area]:after {
                content: "\276E";
            }
        }

        .alice-carousel__next-btn {
            position: absolute;
            top: 30%;
            right: -2vw;
            width: auto;

            [data-area]:after {
                content: "\276F";
            }
        }

        .alice-carousel__prev-btn-item,
        .alice-carousel__next-btn-item {
            font-size: 3rem;
            color: var(--primary-color);

            :hover {
                color: var(--secondary-color);
            }
        }

        .alice-carousel__prev-btn-item.__inactive,
        .alice-carousel__next-btn-item.__inactive {
            opacity: 0.3;
        }
    }
}

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}