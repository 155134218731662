
@font-face {
    font-family: BauerBodoniStd-Roman;
    src: url(./fonts/BauerBodoniStd-Roman.otf);
}

@font-face {
    font-family: PTSans;
    src: url(./fonts/PTSans-Regular.ttf);
}

@font-face {
    font-family: Avenir;
    src: url(./fonts/Avenir-Regular.ttf);
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --primary-color: #e6b10e;
    --secondary-color: #322e2d;
    --third-color: #988e8b;
}