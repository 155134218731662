.arrow-direction {
    position: absolute;
    bottom: 2.5vw;
    right: 8vw;
    width: 35px;
    height: 35px;
    font-size: calc(12px + 1vw);
    border: 2px solid white;
    border-radius: 100%;
    cursor: pointer;

    svg {
        display: flex;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        color: var(--primary-color);
    }
}