.broken-page{
  display: none;
    
}
.broken-page-content{
    display: none;
}
@media screen and(max-width: 1024px) {
    .broken-page{
        display: block; 
       
    }
    
}
@media screen and(max-width: 930px) {
    .broken-page{
        display: flex; 
       
        position: absolute;
        z-index: 500000;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        width: 100%;
        background-color: black;
        color: white;  
       
    }
    .broken-page-content{
        display: block;
        position: absolute;
        padding-left: 30px;
        padding-right: 30px;
    }
  
}